import React from 'react';

// Components
import { Text } from '@chakra-ui/layout';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ to = '', children = '', sx = {} } = {}) => {
  return (
    <GatsbyLink to={to}>
      <Text
        as="span"
        sx={{
          transition: 'filter 100ms',
          ':hover': {
            textDecoration: 'underline',
          },
          ...sx,
        }}
      >
        {children}
      </Text>
    </GatsbyLink>
  );
};

export default Link;
