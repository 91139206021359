import React from 'react';

// Components
import { Box, Heading, Text } from '@chakra-ui/layout';

// Utils
import colors from '../utils/colors';
import Link from '../components/atoms/link';

const NotFoundPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 4,
      }}
    >
      <Box
        sx={{
          WebkitMaskImage: '-webkit-radial-gradient(white, black)', // Required for the border radius to work on Safari
          borderRadius: '128px',
          width: '256px',
          height: '256px',
          marginBottom: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: colors.primary,
        }}
      >
        <Heading
          as="h1"
          sx={{
            fontSize: '8xl',
            color: colors.secondary,
          }}
        >
          404
        </Heading>
      </Box>
      <Heading>The page you're looking for doesn't exist.</Heading>
      <Link to="/" sx={{ marginTop: 4, fontSize: '2xl', fontWeight: 'bold' }}>
        Let's get you{' '}
        <Text as="span" sx={{ color: colors.primary }}>
          home.
        </Text>
      </Link>
    </Box>
  );
};

export default NotFoundPage;
